@import 'assets/fontawesome/scss/fontawesome';
@import 'assets/fontawesome/scss/brands';

$fa-font-path: 'assets/fontawesome/webfonts';

@import 'assets/scss/variables';
@import 'assets/scss/mixins';
@import 'assets/scss/bootstrap';
@import 'assets/scss/theme';



// Custom Styles
.content-container {
	img {
		max-width: 100%;
		height: auto;
	}
  figure.image_resized {
    display: block;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }


  ul,
  ol {
    list-style: disc;
    padding-left: 2em;
    margin-bottom: 1em;
  }


  ol {
    list-style: decimal;
  }
}



.spinner-border {
	width: 6em;
	height: 6em;
	border-width: 0.6em;
  color: #051421;
}
