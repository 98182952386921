


//-------------------- Transform, Translate3d, TranslateX, TranslateY, Rotate --------------------//
@mixin transform($params) {
	-webkit-transform: $params;
	-moz-transform: $params;
	-ms-transform: $params;
	-o-transform: $params;
	transform: $params;
}



//-------------------- Background color and color --------------------//
@function solid-lighten($color, $percentage) {
  @return lighten($color, (1-$percentage)*5%);
}



@function solid-darken($color, $percentage) {
  @return darken($color, (1-$percentage)*5%);
}



@mixin rgba($type, $color, $percentage, $shade: ligthen) {
  @if $shade == lighten {
	@if $type == color {
	  color: solid-lighten($color, $percentage);
	  color: rgba($color, $percentage);
	}
	@if $type == bg {
	  background-color: solid-lighten($color, $percentage);
	  background-color: rgba($color, $percentage);
	}
  }
  @else {
	 @if $type == color {
	  color: solid-darken($color, $percentage);
	  color: rgba($color, $percentage);
	}
	@if $type == bg {
	  background-color: solid-darken($color, $percentage);
	  background-color: rgba($color, $percentage);
	}
  }
}



//-------------------- Responsive --------------------//
@mixin desktop {
  @media (max-width: #{$large-device-width}) {
	@content;
  }
}
@mixin tablet {
  @media (max-width: #{$medium-device-width}) {
	@content;
  }
}
@mixin mobile {
 @media (max-width: #{$small-device-width}) {
	@content;
  }
}
@mixin extra-small-mobile {
  @media (max-width: #{$extra-small-device-width}) {
	@content;
  }
}



// Animations
@keyframes circle {
  0% {
	-webkit-transform: rotate(0);
	-ms-transform: rotate(0);
	-o-transform: rotate(0);
	transform: rotate(0);
  }
  100% {
	-webkit-transform: rotate(360deg);
	-ms-transform: rotate(360deg);
	-o-transform: rotate(360deg);
	transform: rotate(360deg);
  }
}

@keyframes pop-in {
  0% {opacity: 1;}
  100% {
	opacity: 1;
	@include transform (scale(1.1));
  }
}

@keyframes checkmark {
  0% {stroke-dashoffset: 50px}
  100% {stroke-dashoffset: 0}
}

@keyframes checkmark-circle {
  0% {stroke-dashoffset: 240px}
  100% {stroke-dashoffset: 480px}
}
