//-------------------- Colors --------------------//
$color-1: 						#2abfaa;
$color-2: 						#444;
$color-3: 						#32a067;
$color-4: 						#121921;
$color-5: 						#FFC107;

$border-color-2: 			    #ededed;
$border-color-3: 			    #d2d8dd;
$border-color-4: 			    #ccc;
$border-color-5: 			    #ddd;

//-------------------- Responsive sizes -------------------- //
$large-device-width: 1199px; // Large devices (desktops, less than 1200px)
$medium-device-width: 991px; // Medium devices (tablets, less than 992px)
$small-device-width: 767px;	// Small devices (landscape phones, less than 768px)
$extra-small-device-width: 575px;	// Extra small devices (portrait phones, less than 576px)



