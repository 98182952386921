html,
body {
  height: 100%;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 16px;
  background: #051421;
  line-height: 1.4;
  color: #363636;
  font-family: 'Source Sans 3', Helvetica Neue, Arial, sans-serif;
}

p {
  margin-bottom: 1em;
}

label {
  margin-bottom: 3px;
  font-size: 18px;
}

hr {
  margin: 30px 0 30px 0;
  border-color: $border-color-2;
}

h4 {
  color: #17406a;
  font-weight: bold;
  letter-spacing: -1px;
  font-size: 1.75em;
  line-height: 1;

  &.form-heading {
    @media (min-width: #{$small-device-width}) {
      font-size: 2em;
    }

    @media (min-width: #{$medium-device-width}) {
      font-size: 2.25em;
    }
  }
}

a {
  transition: all 0.3s ease-in-out;
  color: #555;
  text-decoration: underline;
  outline: none;

  &:hover,
  &:focus {
    color: #111;
    outline: none;
  }
}



// Buttons
.btn {
  background-color: #f15b56 !important;
  border-color: #f15b56 !important;
  font-size: 20px;
  padding: 0.5em 1em;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  border-radius: 0px;
  text-decoration: none;

  &:hover {
    background-color: #ee3933 !important;
    border-color: #ee3933 !important;
  }
}



// Layout
.mw720 {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}
.row-height {
  height: 100vh;

  @include mobile {
    height: auto;
  }
}

.content-left {
  padding: 0;
  overflow: hidden;
}

.content-right {
  padding: 60px;
  height: 100%;
  min-height: 100%;
  overflow-y: scroll;
  display: flex;
  justify-content: center;

  // align-items: center;
  @include tablet {
    display: block;
    height: auto;
    min-height: inherit;
    padding: 15px 15px 0 15px;
  }

  @include mobile {
    padding: 0 15px 0 15px;
  }
}



// Header
header {
  width: 100%;
  padding: 1em 0;
  background: url('../img/flag-bg-large.jpg') center center no-repeat #1b2945;
  background-size: cover;
}


a#logo {
  display: inline-block;
}



// Footer
footer {
  background: url('../img/flag-bg-large.jpg') center center no-repeat #1b2945;
  color: #fff !important;
  background-size: cover;

  .btn {
    background-color: #003151 !important;
    border-color: #003151 !important;

    &:hover {
      background-color: #00233b !important;
      border-color: #00233b !important;
      color: #fff;
    }
  }

  .footer-copy {
    text-align: left;

    @include mobile {
      text-align: center;
    }
  }

  ul.social {
    margin: 0;
    padding: 0;
    text-align: right;

    @include mobile {
      text-align: center;
      margin-top: 2em;
    }

    li {
      display: inline-block;
      margin: 0 10px;
      list-style: none;

      a {
        color: #18906f;
        text-align: center;
        line-height: 35px;
        display: block;
        font-size: 30px;
        text-decoration: none;

        &:hover {
          color: #fff;
        }
      }
    }
  }
}








main#general_page {
  background-color: #fff;
}




.form-group {
  position: relative;
}


.form-control {
  border: 1px solid $border-color-3;
  color: #717a82;

  &:focus {
    color: #717a82;
    box-shadow: none;
    border-color: $color-1;
  }
}


span.error {
  transition: all 0.3s ease-in-out;
  font-size: 12px;
  position: absolute;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  top: -20px;
  right: -15px;
  z-index: 2;
  height: 25px;
  line-height: 1;
  background-color: #e34f4f;
  color: #fff;
  font-weight: normal;
  display: inline-block;
  padding: 6px 8px;

  &:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 6px 6px 0;
    border-color: transparent #e34f4f;
    display: block;
    width: 0;
    z-index: 1;
    bottom: -6px;
    left: 20%;
  }
}








/*-------- 3.2 Spacing --------*/


.margin_30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.margin_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.margin_60_35 {
  padding-top: 60px;
  padding-bottom: 35px;
}

